function initSearch() {
	const search = document.querySelector('.bowe-search.header-button');

	if (!search) return;

	setupToggle(search);
	setupSearchInput(search);
	setupSubmitButtons(search);
	// Setup keyboard shortcuts
	setupKeyboardShortcuts(search);
}

function setupToggle(search) {
	const toggle = search.querySelector('.trigger');
	const popupWrapper = search.querySelector('.popup');
	const inputField = search.querySelector('#page-search');
	const body = document.querySelector('body');

	if (!toggle) return;

	toggle.addEventListener('click', () => {
		popupWrapper.classList.toggle('active');
		toggle.classList.toggle('active');
		inputField.value = ''; //Resets
		setTimeout(() => {
			inputField.focus(); // Delay focus to ensure the field is visible and ready
		}, 100); // Adjust the timeout duration as needed
		body.classList.toggle('stop-scroll');
	});
}

function setupSubmitButtons(search) {
	const formSubmit = search.querySelector('.submit-hidden');
	const submitButtons = search.querySelectorAll('.submit-button');
	submitButtons.forEach((item) => {
		item.addEventListener('click', (e) => {
			e.preventDefault();
			formSubmit.click();
		});
	});
}

function setupSearchInput(search) {
	const searchInput = search.querySelector('#page-search');
	const searchSuggestions = search.querySelector('.search-form-suggestions');
	const resultsWrapper = search.querySelector('.search-form-result');
	//Results
	const searchResultCount = search.querySelectorAll('.search-result-count');
	const submitButtons = search.querySelectorAll('.submit-button');

	const resultProducts = search.querySelector(
		'.search-form-result-products-list'
	);

	const resultCategories = search.querySelector(
		'.search-form-result-list-result.categories'
	);

	const resultArticles = search.querySelector(
		'.search-form-result-list-result.articles'
	);

	const minlength = 2;
	let timer;
	const waitTime = 500; // Wait time in milliseconds

	searchInput.addEventListener('input', function () {
		if (this.value.length >= minlength) {
			clearTimeout(timer); // Clear timer
			timer = setTimeout(() => {
				//resultsWrapper.classList.add("animation");

				fetcherSearch(this.value)
					.then((data) => {
						let totalFound = data?.total_posts;
						//Hide suggestions
						searchSuggestions.classList.add('hide');
						resultsWrapper.classList.remove('hide');

						resultProducts.innerHTML = data?.products_html;
						resultCategories.innerHTML = data?.categories_html;
						resultArticles.innerHTML = data?.guides_html;

						searchResultCount.forEach((item) => {
							item.innerHTML = `(${totalFound})`;
						});

						submitButtons.forEach((item) => {
							if (totalFound > 8) {
								item.classList.remove('hide');
							} else {
								item.classList.add('hide');
							}
						});

						//variantImages.innerHTML = data?.html;
					})
					.catch((error) => {
						console.log(error);
					});
			}, waitTime);
		} else {
			searchSuggestions.classList.remove('hide');
			resultsWrapper.classList.add('hide');

			// Reset search functionality
		}
	});
}

async function fetcherSearch(string) {
	const queryString = new URLSearchParams({
		string: string,
	}).toString();

	// Determine the base URL based on the current location
	const baseUrl = window.location.pathname.startsWith('/us') ? '/us' : '';

	const response = await fetch(
		`${baseUrl}/wp-json/bowe/v1/search?${queryString}`,
		{
			method: 'GET',
		}
	);

	if (!response.ok) {
		throw new Error('Network response was not ok');
	}

	return response.json();
}

function setupKeyboardShortcuts(search) {
	const popupTrigger = search.querySelector('.search.trigger');

	document.addEventListener('keydown', function (e) {
		// Toggle search with Command + K or Ctrl + K
		if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
			e.preventDefault(); // Prevent default browser behavior
			popupTrigger.click();
		}

		// Close search with Escape key
		if (e.key === 'Escape') {
			popupTrigger.click();
		}
	});
}

document.addEventListener('DOMContentLoaded', initSearch);
